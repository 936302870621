<template>
    <div id="content" role="main">
        <h1>Public Interest Legal Career Fair Employer Information</h1>

        <p>
            Welcome to the {{ fairYear }} Public Interest Legal Career Fair! The Fair will
            take place virtually on Thursday and Friday, February {{ startDate }} & {{ endDate }}, {{ fairYear
            }}
        </p>
        <p>
            NYU’s Public Interest Legal Career Fair is one of the largest fairs of its kind in the country. Last year’s
            fair hosted over 300 employers and
            over 2,000 students from 20 area law schools (return to the for a
            full list of participating schools). Whether you are seeking to fill
            permanent positions or to find summer interns, the Fair is an
            efficient way for busy public interest employers to connect with
            dedicated students.
        </p>
        <p>
            There is no participation fee for employers, and you may participate
            in whatever way you find most useful. You may attend the Fair for up
            to two full days, or for as little as half a day. You may host an
            information table, conduct interviews, or both. Please note:
            Interviews and Table Talk will run concurrently. If you wish to
            participate in both, at least two representatives from your office
            must participate--one to conduct interviews and one to speak with
            students at Table Talk.
        </p>
        <p>
            <strong>
                Employer registration opens at 9:00 a.m. on October 15, 2024. The deadline to
                register is November 30, 2024 at 5:00 p.m.
            </strong>
        </p>
        <!-- <p>
            <strong>New Employers:</strong> If your organization is attending
            the Fair for the first time, please register using the “PILC
            Employer Registration” button below and following the instructions.
            After you provide your contact information, you will be given a
            password and will be able to log in using the “Employer Login”
            button below. Fair
        </p>

        <p>
            <strong>Returning Employers:</strong> if your organization has
            participated in the Fair in past years, please use the “Employer
            Login” button below to log in to your account using your username
            and password from prior years. If you do not remember your password,
            please . Please note: Even though your organization has an account
            in LCFS, you will not be registered for the 2021 Fair until you
            complete 4 steps. Follow the instructions provided after logging in
            to the system.
        </p> -->

        <p>
            (return to the
            <a href="https://info.pilcfair.law.nyu.edu/">main information page</a>
            for a full list of schools)
        </p>

        <p><a href="#top">top of page</a></p>
    </div>
    <!-- #content -->
</template>

<script>
import { useFairDatesStore } from '@/store/fairdates';

export default {
    name: "EmployerPageContent",
    data() {
        return {
            fairYear: '20XX',
            startDate: '?',
            endDate: '?'
        }
    },
    mounted() {
        const fairDateStore = useFairDatesStore();

        const fairYear = fairDateStore.fairStartDate.getFullYear();

        const startDate = fairDateStore.fairStartDate.getDate();
        const endDate = fairDateStore.fairEndDate.getDate();

        this.fairYear = fairYear;
        this.startDate = startDate;
        this.endDate = endDate;
    }
};
</script>
