<template>
    <div id="content">
        <h1>
            Public Interest Legal Career Fair<br />
            Law School Administrator Information
        </h1>

        <p>
            Welcome to the 2025 Public Interest Legal Career Fair! The Fair will
            take place virtually at New York University School of Law on Thursday and Friday, February
            {{ startDate }} &amp; {{ endDate }}, {{ fairYear }}.
        </p>
        <p>
            You will be able to log in to the Fair site on October 1 if you have paid the participation fee.
        </p>
        <p>
            <u>Please note:</u> The Public Interest Legal Career Fair is only
            open to students and alumni from participating law schools (return
            to the
            <a href="https://info.pilcfair.law.nyu.edu/">main information page</a>
            for a full list).
        </p>
        <p>&nbsp;</p>
    </div>
    <!-- #content -->
</template>

<script>
import { useFairDatesStore } from "@/store/fairdates";

export default {
    name: "LawSchoolAdminPageContent",
    data() {
        return {
            fairYear: "20XX",
            startDate: "?",
            endDate: "?",
        };
    },
    mounted() {
        const fairDateStore = useFairDatesStore();

        const fairYear = fairDateStore.fairStartDate.getFullYear();

        const startDate = fairDateStore.fairStartDate.getDate();
        const endDate = fairDateStore.fairEndDate.getDate();

        this.fairYear = fairYear;
        this.startDate = startDate;
        this.endDate = endDate;
    },
};
</script>
