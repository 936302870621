import { createMemoryHistory, createRouter } from 'vue-router'

import HomePageContent from '../components/HomePageContent.vue';
import EmployerPageContent from '../components/EmployerPageContent.vue';
import StudentPageContent from '../components/StudentPageContent.vue';
import LawSchoolAdminPageContent from '../components/LawSchoolAdminPageContent.vue';


const routes = [
    { path: '/', component: HomePageContent },
    { path: '/employerinformation', component: EmployerPageContent },
    { path: '/studentinformation', component: StudentPageContent },
    { path: '/lawschooladmininformation', component: LawSchoolAdminPageContent },
]

const router = createRouter({
    history: createMemoryHistory(),
    routes,
});

export default router;