<template>
    <div id="content" role="main">

        <h1>{{ fairYear }} Public Interest Legal Career Fair</h1>

        <p>The Public Interest Law Center (PILC) at New York University School of Law will hold its 48th annual
            Public Interest Legal Career Fair on February {{ startDate }} &amp; {{ endDate }}, {{ fairYear }}. It is one
            of the largest fairs of its kind in the country.</p>
        <p>The Fair provides a forum where employers and students can connect with one another, both during
            interviews for internships and permanent jobs, as well as more informally at information tables. Last
            year, representatives from over 300 employers participated in the Fair, along with over 2,000 law students
            from the 20 participating law schools listed below.</p>
        <p>If you have any questions or concerns after reviewing the information available using the links to the
            left, please contact NYU Law School's Public Interest Law Center at <a
                href="mailto:law.pilcfair@nyu.edu">law.pilcfair@nyu.edu</a>
            or 212-998-6686.</p>

        <p style="margin: 0px 0px 0px;">&nbsp;</p>
        <p style="margin: 0px 0px 0px;"><u>Participating Schools</u></p>

        <p style="margin: 0px 0px 0px;">Benjamin N. Cardozo School of Law</p>

        <p style="margin: 0px 0px 0px;">Brooklyn Law School</p>

        <p style="margin: 0px 0px 0px;">Columbia Law School</p>

        <p style="margin: 0px 0px 0px;">Cornell Law School</p>

        <p style="margin: 0px 0px 0px;">CUNY School of Law</p>

        <p style="margin: 0px 0px 0px;">Fordham University School of Law</p>

        <p style="margin: 0px 0px 0px;">Hofstra University School of Law</p>

        <p style="margin: 0px 0px 0px;">New York Law School</p>

        <p style="margin: 0px 0px 0px;">New York University School of Law</p>

        <p style="margin: 0px 0px 0px;">Pace University School of Law</p>

        <p style="margin: 0px 0px 0px;">Quinnipiac University School of Law</p>

        <p style="margin: 0px 0px 0px;">Roger Williams University School of Law</p>

        <p style="margin: 0px 0px 0px;">Rutgers Law School</p>

        <p style="margin: 0px 0px 0px;">Seton Hall University School of Law</p>

        <p style="margin: 0px 0px 0px;">St. John's University School of Law</p>

        <p style="margin: 0px 0px 0px;">Syracuse University College of Law</p>

        <p style="margin: 0px 0px 0px;">Touro Law Center</p>

        <p style="margin: 0px 0px 0px;">University at Buffalo School of Law</p>

        <p style="margin: 0px 0px 0px;">University of Connecticut School of Law</p>

        <p style="margin: 0px 0px 0px;">Yale Law School</p>

        <p><a href="#top">top of page</a></p>

    </div><!-- #content -->
</template>

<script>
import { useFairDatesStore } from '@/store/fairdates';

export default {
    name: 'HomePageContent',
    data() {
        return {
            fairYear: '20XX',
            startDate: '?',
            endDate: '?'
        }
    },
    mounted() {
        const fairDateStore = useFairDatesStore();

        const fairYear = fairDateStore.fairStartDate.getFullYear();

        const startDate = fairDateStore.fairStartDate.getDate();
        const endDate = fairDateStore.fairEndDate.getDate();

        this.fairYear = fairYear;
        this.startDate = startDate;
        this.endDate = endDate;
    }
}
</script>