import { defineStore } from 'pinia';
import { ref } from 'vue';

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useFairDatesStore = defineStore('fairDates', () => {
    // other options...
    const fairStartDate = ref(new Date(2025, 1, 6));
    const fairEndDate = ref(new Date(2025, 1, 7));

    const employerRegStart = ref(new Date(2024, 9, 15, 9));
    const employerRegEnd = ref(new Date(2024, 10, 15, 17));

    // student registration & application period
    const studentRegStart = ref(new Date(2024, 10, 15, 9));
    const studentRegEnd = ref(new Date(2025, 0, 16, 17));

    // late student registration
    const lateStudentRegStart = ref(new Date(2025, 0, 16, 17, 1));
    const lateStudentRegEnd = ref(new Date(2025, 1, 3, 8));

    // Employer Application Review Period
    const employerAppReviewStart = ref(new Date(2025, 0, 17, 9));
    const employerAppReviewEnd = ref(new Date(2025, 0, 29, 17));

    // Expected release of interview schedules
    const expectedReleaseOfInterviews = ref(new Date(2025, 0, 31));

    // Free Signup
    const freeSignupStart = ref(new Date(2025, 1, 3, 9));
    const freeSignupEnd = ref(new Date(2025, 1, 5, 15));

    // Table Talk (in Flo Recruit)
    const tableTalkStart = ref(new Date(2025, 1, 3, 9));
    const tableTalkEnd = ref(new Date(2025, 1, 5, 15));

    // Deadline for students to cancel interviews 
    const interviewCancelDeadline = ref(new Date(2025, 1, 5, 15));

    // Deadline for schools to send us their checks
    const checkDeadline = ref(new Date(2024, 8, 15));

    // Deadline for employers to finalize participation plans or withdraw from the Fair 
    const participationPlanDeadline = ref(new Date(2024, 10, 15));

    // Deadline for employers to enter representatives into the system 
    const employerRepDeadline = ref(new Date(2024, 11, 15));

    return {
        fairStartDate,
        fairEndDate,
        employerRegStart,
        employerRegEnd,
        studentRegStart,
        studentRegEnd,
        lateStudentRegStart,
        lateStudentRegEnd,
        employerAppReviewStart,
        employerAppReviewEnd,
        expectedReleaseOfInterviews,
        freeSignupStart,
        freeSignupEnd,
        tableTalkStart,
        tableTalkEnd,
        interviewCancelDeadline,
        checkDeadline,
        participationPlanDeadline,
        employerRepDeadline
    };
})