<template>
    <div id="content" role="main">
        <h1>Public Interest Legal Career Fair<br />Student Information</h1>

        <p>
            Welcome to the {{ fairYear }} Public Interest Legal Career Fair! The Fair will
            take place virtually on Thursday and Friday, February {{ startDate }} &amp;
            {{ endDate }}, {{ fairYear }}.
        </p>
        <p>
            Students may register for the Fair and submit applications to
            employers beginning on November 15, 2024 at 9:00 a.m.
            <strong>The deadline to register and upload applications is January 16,
                2025 at 5:00 p.m.</strong>
        </p>

        <p>
            <u>Please note:</u> The Public Interest Legal Career Fair is only
            open to students from participating law schools (return to the
            <a href="https://info.pilcfair.law.nyu.edu/">main information page</a>
            for a full list).
        </p>

        <p style="margin: 0px 0px 0px">&nbsp;</p>
        <p>
            <strong>Student registration and login are currently closed. Please
                check back on November 15, 2024 at 9:00 a.m. for registration
                and login information.</strong>
        </p>
    </div>
    <!-- #content -->
</template>

<script>
import { useFairDatesStore } from "@/store/fairdates";

export default {
    name: "StudentPageContent",
    data() {
        return {
            fairYear: "20XX",
            startDate: "?",
            endDate: "?",
        };
    },
    mounted() {
        const fairDateStore = useFairDatesStore();

        const fairYear = fairDateStore.fairStartDate.getFullYear();

        const startDate = fairDateStore.fairStartDate.getDate();
        const endDate = fairDateStore.fairEndDate.getDate();

        this.fairYear = fairYear;
        this.startDate = startDate;
        this.endDate = endDate;
    },
};
</script>
