<template>
  <div class="page" id="page">
    <header id="header">
      <section id="header-1" role="banner" aria-label="utility-bar">
        <div id="utility-bar">
          <div class="container-12">
            <div id="skip-to-main-content"><span></span>
              <a href="#content">Skip to main content</a>
            </div>
            <div id="support-nyulaw"><span></span>
              <a href="https://www.law.nyu.edu/giving/campaign">Support NYU
                Law</a>
            </div>
            <div id="quicklinks" role="navigation" aria-label="quicklinks-menu" tabindex="0"><span></span>
              QUICKLINKS
              <div id="quicklinks-submenu">
                <ul>
                  <li><a href="https://www.law.nyu.edu/areasofstudy">Areas of
                      Study</a></li>
                  <li><a href="https://its.law.nyu.edu/eventcalendar/">Calendar</a>
                  </li>
                  <li><a href="https://www.law.nyu.edu/students/career-resources">Careers</a>
                  </li>
                  <li><a href="https://www.law.nyu.edu/academics/colloquia">Colloquia</a>
                  </li>
                  <li><a href="https://its.law.nyu.edu/courses/">Courses</a>
                  </li>
                  <li><a href="https://its.law.nyu.edu/directories/index.cfm?fuseaction=home.contact">Departments</a>
                  </li>
                  <li><a href="https://its.law.nyu.edu/directories/">Directories</a>
                  </li>
                  <li><a href="https://blogs.law.nyu.edu/docket/">Docket</a>
                  </li>
                  <li><a href="https://www.law.nyu.edu/event-planning">Event
                      Planning</a></li>
                  <li><a href="https://www.law.nyu.edu/housing">Housing</a>
                  </li>
                  <li><a href="https://www.law.nyu.edu/library">Library</a>
                  </li>
                  <li><a href="https://www.law.nyu.edu/news">News and
                      Press</a></li>
                  <li><a href="https://home.nyu.edu/">NYU Home</a></li>
                  <li><a href="https://www.law.nyu.edu/technology">Technology</a>
                  </li>
                </ul>
              </div>
            </div>
            <div id="global-search">
              <form id="global-search-form" action="https://www.law.nyu.edu/search" method="get" accept-charset="UTF-8">
                <input aria-label="Search" role="search" type="text" id="q" name="q" size="60" maxlength="128">
                <button type="submit" id="edit-submit" value="&amp;rsaquo;">›</button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section id="header-2" role="banner" aria-label="branding-and-main-menu">
        <div class="container-12">
          <div class="grid-3">
            <a href="https://www.law.nyu.edu/"><img src="./assets/logo.png" alt="NYU Law Logo"></a>
          </div>
          <div class="grid-9">
            <div id="primary-menu" role="navigation" aria-label="primary-menu">
              <ul>
                <li><a href="https://www.law.nyu.edu/jdadmissions">JD
                    Admissions</a></li>
                <li><a href="https://www.law.nyu.edu/llmjsd">LLM/JSD
                    Admissions</a></li>
                <li><a href="https://www.law.nyu.edu/executive-programs">Executive
                    Programs</a></li>
                <li><a href="https://www.law.nyu.edu/about">About NYU Law</a>
                </li>
              </ul>
              <ul>
                <li><a href="https://www.law.nyu.edu/faculty">Faculty &amp;
                    Scholarship</a></li>
                <li><a href="https://www.law.nyu.edu/academics">Academics
                    &amp; Courses</a></li>
                <li><a href="https://www.law.nyu.edu/students">Current
                    Students</a></li>
                <li><a href="https://www.law.nyu.edu/alumni">Alumni &amp;
                    Giving</a></li>
              </ul>
              <ul>
                <li><a href="https://www.law.nyu.edu/global">Global
                    Programs</a></li>
                <li><a href="https://www.law.nyu.edu/lawbusiness">Law &amp;
                    Business</a></li>
                <li><a href="https://www.law.nyu.edu/publicservice">Public
                    Service</a></li>
                <li><a href="https://www.law.nyu.edu/centers">Centers &amp;
                    Institutes</a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </header>
    <div class="content clearfix container-12">

      <div id="its_main" class="clearfix CareerFair">
        <aside id="region-sidebar-first" class="grid-2 region region-sidebar-first" role="complementary"
          aria-label="sidebar-left">

          <div class="region-inner region-sidebar-first-inner">
            <div id="" class="block block-menu-block" role="navigation" aria-label="local-left-hand-menu">
              <div class="block-inner clearfix">
                <h2 class="block-title">

                  Career Fair</h2>
                <div class="content clearfix">
                  <div class="menu-block-wrapper menu-block-3 menu-name-main-menu parent-mlid-0 menu-level-1">
                    <ul class="menu">

                      <li class="">
                        <RouterLink to="/studentinformation" target="_top">Students</RouterLink>
                      </li>
                      <li class="">
                        <RouterLink to="/employerinformation" target="_top">Employers</RouterLink>
                      </li>
                      <li class="">
                        <RouterLink to="/lawschooladmininformation" target="_top">Law School Administrators</RouterLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
        <div id="content-wrap" class="grid-10 no-floats">

          <!-- <a id="print-button" href="general-content-print.html">Print this page</a> -->

          <div class="breadcrumb">
            <a href="https://www.law.nyu.edu/">Home</a> › <a
              href="https://www.law.nyu.edu//publicinterestlawcenter">Public
              Interest Law Center</a> ›
            <RouterLink to="/">Career Fair</RouterLink> ›
          </div>

          <!-- Place vue content here-->
          <RouterView />

          <div class="clear"> </div>
        </div><!-- #content-wrap -->
        <div class="clear"> </div>
      </div><!-- #main -->

    </div><!-- end content -->
    <footer id="footer">
      <div id="footer-content" role="contentinfo" aria-label="utility-footer">
        <ul>
          <li><a href="https://www.facebook.com/nyulaw" id="facebook-mi" class="ir" rel="nofollow"
              target="_blank">Facebook</a></li>
          <li><a href="https://twitter.com/nyulaw" id="twitter-mi" class="ir" rel="nofollow" target="_blank">Twitter</a>
          </li>
          <li><a href="https://www.youtube.com/user/nyuschooloflaw" class="ir" id="youtube-mi" rel="nofollow"
              target="_blank">YouTube</a></li>
          <li><a href="https://www.instagram.com/nyulaw/" id="instagram-mi" class="ir" rel="nofollow"
              target="_blank">Instagram</a></li>
          <li><a href="https://www.nyu.edu/accessibility">Accessibility</a>
          </li>
          <li><a href="https://its.law.nyu.edu/directories/">Directories</a>
          </li>
          <li><a href="https://its.law.nyu.edu/directories/index.cfm?fuseaction=home.contact">Offices
              and Departments</a></li>
          <li><a href="https://www.law.nyu.edu/sitemap" id="sitemap">Site
              Map</a></li>
          <li><a href="https://www.law.nyu.edu/about/visitorinformation">Visitor
              Information</a></li>
          <li><a href="https://www.law.nyu.edu/" id="footer-home-mi" class="ir">NYU School of Law</a></li>
        </ul>
      </div>
      <div id="footer-note" role="contentinfo" aria-label="school-info-footer">
        <p>© 2024 New York University School of Law. 40 Washington Sq. South, New York, NY 10012. &nbsp;Tel.
          212.998.6100</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#page {
  background: url("./assets/page-bg.png") repeat-y center 0;
}
</style>